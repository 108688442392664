import { setOzonCookieToBrowser } from "api/MarketplaceAuth";
import { updateOzonCookieFromBrowser } from "api/MarketplaceAuth";
import { addJob, clearJobs } from "api/Reviews";
import {
  productsOzonLoadProducts,
  productsWbLoadProducts,
  reviewsOzonJobApprove,
  reviewsOzonJobGenerate,
  reviewsOzonJobLoadNew,
  reviewsOzonJobLoadOld,
  reviewsOzonJobReset,
  reviewsOzonJobSend,
  reviewsWbJobApprove,
  reviewsWbJobGenerate,
  reviewsWbJobLoadNew,
  reviewsWbJobLoadOld,
  reviewsWbJobReset,
  reviewsWbJobSend,
} from "queue";

export const common = [
  {
    placeholder: "Обновить куки Ozon из браузера",
    onClick: () => updateOzonCookieFromBrowser(),
  },
  {
    placeholder: "Проброосить куки Ozon в браузер",
    onClick: () => setOzonCookieToBrowser(),
  },
];

export const loadProductsList = [
  {
    placeholder: "Подтянуть список товаров с WB",
    onClick: () => addJob({ name: productsWbLoadProducts, data: {} }),
  },
  {
    placeholder: "Подтянуть список товаров с OZON",
    onClick: () =>
      addJob({ name: productsOzonLoadProducts, data: { markeplace: "ozon" } }),
  },
];

export const reviewsWb = [
  {
    placeholder: "Выгрузить новые отзывы",
    onClick: () => addJob({ name: reviewsWbJobLoadNew, data: {} }),
  },
  {
    placeholder: "Выгрузить старые отзывы",
    onClick: () => addJob({ name: reviewsWbJobLoadOld, data: {} }),
  },
  {
    placeholder: "Сбросить состояние отпарвки",
    onClick: () => addJob({ name: reviewsWbJobReset, data: {} }),
  },
  {
    placeholder: "Сгенерировать ответы",
    onClick: () => addJob({ name: reviewsWbJobGenerate, data: {} }),
  },
  {
    placeholder: "Апрувнуть 500 отзывов",
    onClick: () => addJob({ name: reviewsWbJobApprove, data: {} }),
  },
  {
    placeholder: "Отправить ответы на отзывы",
    onClick: () => addJob({ name: reviewsWbJobSend, data: {} }),
  },
];

export const reviewsOzon = [
  {
    placeholder: "Выгрузить новые отзывы",
    onClick: () =>
      addJob({ name: reviewsOzonJobLoadNew, data: { marketplace: "ozon" } }),
  },
  {
    placeholder: "Выгрузить старые отзывы",
    onClick: () =>
      addJob({ name: reviewsOzonJobLoadOld, data: { marketplace: "ozon" } }),
  },
  {
    placeholder: "Сбросить состояние отправки",
    onClick: () =>
      addJob({ name: reviewsOzonJobReset, data: { marketplace: "ozon" } }),
  },
  {
    placeholder: "Сгенерировать ответы",
    onClick: () =>
      addJob({ name: reviewsOzonJobGenerate, data: { marketplace: "ozon" } }),
  },
  {
    placeholder: "Апрувнуть 500 отзывов",
    onClick: () =>
      addJob({ name: reviewsOzonJobApprove, data: { marketplace: "ozon" } }),
  },
  {
    placeholder: "Отправить ответы на отзывы",
    onClick: () =>
      addJob({ name: reviewsOzonJobSend, data: { marketplace: "ozon" } }),
  },
];
