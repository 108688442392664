import fetchApiWithAuth from "./fetch";
import fetchApi from "./fetch";

type AnswerMain = {
  status: string;
  text: string;
  created_at: string;
  published_at: string | null;
  generated: boolean;
};

type ReviewMain = {
  id: number;
  external_id: string;
  marketplace: string;
  sku: string;
  rating: number;
  text: { comment: string; positive: string; negative: string };
  author_name: string;
  published_at: string;
  brand: string;
  article: string;
  productName: string;
  media_links?: { photos?: string[] };
  status?: string;
};

export type ReviewsProps = ReviewMain & { answer?: AnswerMain };

export const getReviewsByFilter = async (props: {
  marketplace?: string[];
  rating?: number[];
  article?: string;
  status?: string[];
  sorting: string;
  page: number;
}) => {
  const result = await fetchApiWithAuth("/api/reviews", {
    method: "POST",
    body: JSON.stringify(props),
  });

  const data = (await result.json()) as {
    total: number;
    total_page: number;
    data: ReviewsProps[];
  };
  return data;
};

export const updateReview = async (props: {
  id: number;
  data: Partial<ReviewsProps>;
}) => {
  const result = await fetchApiWithAuth(`/api/reviews/${props.id}`, {
    method: "PATCH",
    body: JSON.stringify(props.data),
  });

  return (await result.json()) as ReviewsProps;
};

export type Variants = {
  name: string;
  templateText: string;
  error?: string;
  text?: string;
}[];

export const getAnswerVariantsById = async (props: { id: number }) => {
  const result = await fetchApiWithAuth(
    `/api/reviews/${props.id}/answer_variants`,
    {
      method: "GET",
    }
  );

  return (await result.json()) as Variants;
};

export type BadGoods = {
  article: string;
  rating: number;
  error: string;
  count: number;
};

export const getBadGoods = async () => {
  const result = await fetchApiWithAuth(`/api/reviews/bad_goods`, {
    method: "GET",
  });

  return (await result.json()) as BadGoods[];
};

export type FilterOptions = {
  marketplaces: string[];
};

export const getFilterOptions = async () => {
  const result = await fetchApiWithAuth(`/api/reviews/filter_options`, {
    method: "GET",
  });

  return (await result.json()) as FilterOptions;
};

export const addJob = async (data: {
  name: string;
  data: Record<string, string>;
}) => {
  return await fetchApiWithAuth(`/api/reviews/jobs`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const clearJobs = async () => {
  return await fetchApiWithAuth(`/api/reviews/jobs/clear`, {
    method: "POST",
    body: JSON.stringify({}),
  });
};
