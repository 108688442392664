import React from 'react'
import styled from 'styled-components/macro'
import Modal from '../Modal'
import { Button, ButtonColor } from '../ui'
import ReactDOM from 'react-dom'
import { modalRoot } from '../Portal'

const Content = styled.div`
  padding: 40px;
`

const ButtonWrap = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 32px;
  }
`

interface AlertOptions {
  submit?: { text?: string; color?: ButtonColor }
  durationAutoClose?: number
}

const AlertPopup = (props: React.PropsWithChildren<{ options?: AlertOptions; onClose: () => void }>) => {
  return (
    <Modal withoutPadding onClose={props.onClose}>
      <Content>
        {props.children}
        <ButtonWrap>
          <Button onClick={props.onClose} color={props.options?.submit?.color || 'azure'}>
            {props.options?.submit?.text || 'Понятно'}
          </Button>
        </ButtonWrap>
      </Content>
    </Modal>
  )
}

export const alertPopup = (children: React.ReactElement | string, options?: AlertOptions) => {
  const node = document.createElement('div')
  const unmount = () => {
    ReactDOM.unmountComponentAtNode(node)
    if (node?.parentNode) node.parentNode.removeChild(node)
  }
  modalRoot?.appendChild(node)

  return new Promise((res) => {
    let timer: number | undefined = undefined
    const handleAction = (value: boolean) => {
      clearTimeout(timer)
      unmount()
      res(value)
    }
    if (options?.durationAutoClose) {
      timer = setTimeout(handleAction, options.durationAutoClose)
    }
    ReactDOM.render(
      <AlertPopup onClose={() => handleAction(false)} options={options}>
        {children}
      </AlertPopup>,
      node
    )
  })
}

export default alertPopup
