import React, { useState } from "react";
import { Button } from "antd";
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import alertPopup from "shared/AlertPopup";
import styled from "styled-components";
import colors from "shared/colors";
interface ButtonWithStateProps {
  placeholder: string;
  onClick: () => Promise<Response>;
}
const Icon = styled.span`
  width: 10px;
  margin: 0 4px 0 -12px;
`;
const ButtonWithState: React.FC<ButtonWithStateProps> = ({
  onClick,
  placeholder,
}) => {
  const [pending, setPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClick = async () => {
    setPending(true);
    try {
      const response = await onClick();
      if (response.status >= 200 && response.status < 300) {
        setIsSuccess(true);

        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
      } else {
        throw new Error("Код ответа не 200");
      }
      setPending(false);
    } catch (err: any) {
      setPending(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 2000);
      await alertPopup(
        <>
          <b>{placeholder}</b>
          <br />
          {err.code && `${err.code} - `}
          {err?.message}
        </>
      );
    }
  };

  return (
    <Button onClick={handleClick} disabled={pending}>
      <Icon>
        {pending && <LoadingOutlined style={{ color: colors.main }} />}
        {isSuccess && <CheckOutlined style={{ color: colors.main }} />}
        {isError && <CloseOutlined style={{ color: colors.warning }} />}
      </Icon>
      {placeholder}
    </Button>
  );
};

export default ButtonWithState;
