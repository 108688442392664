import React, { useEffect, useState, useMemo, FormEvent, useRef } from "react";

import { Table, Button, Row, Col, Card, List } from "antd";

import { setOzonCookieToBrowser } from "api/MarketplaceAuth";

import ButtonWithState from "./ButtonWIthState";
import { common, loadProductsList, reviewsOzon, reviewsWb } from "./buttonList";
import { clearJobs } from "api/Reviews";

const DelayJobs = () => {
  return (
    <>
      <Card title="Общее">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            {common.map((item) => (
              <ButtonWithState
                placeholder={item.placeholder}
                onClick={item.onClick}
              />
            ))}
          </Col>
          <Col xs={24} md={12}>
            {loadProductsList.map((item) => (
              <ButtonWithState
                placeholder={item.placeholder}
                onClick={item.onClick}
              />
            ))}
          </Col>
        </Row>
      </Card>

      <Card title="Отзывы">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <ButtonWithState
              placeholder={"Отчистить очередь отзывов"}
              onClick={() => clearJobs()}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            {reviewsWb.map((item) => (
              <ButtonWithState
                placeholder={item.placeholder}
                onClick={item.onClick}
              />
            ))}
          </Col>

          <Col xs={24} md={12}>
            {reviewsOzon.map((item) => (
              <ButtonWithState
                placeholder={item.placeholder}
                onClick={item.onClick}
              />
            ))}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DelayJobs;
